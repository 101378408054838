import { env as envPublic } from '$env/dynamic/public';
import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

// Dynamically import the publisher-specific redirects
const { clientRedirects = {} } = await import(`$lib/publishers/${envPublic.PUBLIC_PUBLISHER}/redirects.js`);

// Initialize Sentry
Sentry.init({
  dsn: envPublic.PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.1,
  environment: envPublic.PUBLIC_ENV || 'production',
  integrations: [],
});

// Handle client-side error with Sentry
export const handleError = handleErrorWithSentry();

// Apply client-side redirects
for (const [key, value] of Object.entries(clientRedirects)) {
  if (window.location.hash === key) {
    window.location.replace(`${window.origin}${value}`);
  }
}
